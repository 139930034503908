import * as React from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Stack } from '@mui/system'
import { Button, Paper, useMediaQuery } from '@mui/material'
import { Navbar } from './components/Navbar'

import { useNavigate } from 'react-router-dom'

export default function App() {
  const navigate = useNavigate()
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)')

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Stack direction="column" justifyContent="center" alignItems="center" gap="1rem">
          <img src="/assets/token.svg" height={128} />
          <Typography variant="h5" component="p">
            Select a farm.
          </Typography>
          <Typography variant="subtitle1" component="p">
            Earn OSAK tokens by providing liquidity.
          </Typography>

          {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
          {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
        </Stack>

        <Container maxWidth="sm" className="mt-8">
          <Stack direction={isSmallDevice ? 'column' : 'row'} justifyContent="center" alignItems="center" gap="3rem">
            <Paper>
              <Stack width={280} direction="column" justifyContent="center" alignItems="center" gap="1rem" className="p-8">
                <div className="circle">
                  <img src="/assets/face.svg" height={42} />
                </div>
                <Typography variant="h5" component="h3" fontWeight="500">
                  UNI_LP Farm
                </Typography>
                <Typography variant="subtitle2" component="p">
                  <b>Farm has ended.</b>
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Earn OSAK for 45 days
                </Typography>
                <Button variant="contained" className="rounded-lg" fullWidth onClick={() => navigate('/first-farm')}>
                  Select
                </Button>
              </Stack>
            </Paper>

            <Paper>
              <Stack width={280} direction="column" justifyContent="center" alignItems="center" gap="1rem" className="p-8">
                <div className="circle">
                  <img src="/assets/face.svg" height={42} />
                </div>
                <Typography variant="h5" component="h3" fontWeight="500">
                  UNI_LP Farm
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Deposit UNI-V2
                </Typography>
                <Typography variant="subtitle2" component="p">
                  Earn OSAK for 180 days
                </Typography>
                <Button variant="contained" className="rounded-lg" onClick={() => navigate('/second-farm')} fullWidth>
                  Select
                </Button>
              </Stack>
            </Paper>
            {/* <Paper>
              <Typography variant="h5" component="h3">
                Select a farm.
              </Typography>
            </Paper> */}
          </Stack>
        </Container>
      </Container>
    </>
  )
}
