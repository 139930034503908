import * as React from 'react'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'

import { Stack } from '@mui/system'
import { Button, colors, Paper } from '@mui/material'

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

export default function App() {
  return (
    <>
      <Stack direction="row" justifyContent="center" alignItems="center" gap="1rem" marginTop="8rem">
        <img src="/assets/art.svg" width={128} />

        <Stack direction="column" justifyContent="center" alignItems="center" gap="2rem">
          <Typography variant="h4" component="p">
            This wallet unfortunately do not have any claims.
          </Typography>
          <Typography variant="h4" component="p">
            You can buy{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              OSAK
            </Typography>{' '}
            from Uniswap{' '}
            <Typography variant="h4" component="a" color={colors.blue[500]} fontWeight={700} href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0xa21af1050f7b26e0cff45ee51548254c41ed6b5c" target="_blank">
              here
            </Typography>
            .
          </Typography>

          {/* <Button variant="contained" className="rounded-lg">
            Claim now
          </Button> */}
        </Stack>

        {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
        {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
      </Stack>
    </>
  )
}
