import * as React from 'react'
import { useEffect } from 'react'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Claim from './Claim'
import NoClaim from './NoClaim'
import { Stack } from '@mui/system'
import { Button, Paper } from '@mui/material'
import { Navbar } from './components/Navbar'
import { Footer } from './components/Footer'

// import * as htmlToImage from 'html-to-image'
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image'

import { atom, useAtom } from 'jotai'
import { accountAtom, claimableAtom, connectWallet } from './utils/store'

export default function App() {
  const [account] = useAtom(accountAtom)
  const [claimable] = useAtom(claimableAtom)

  useEffect(() => {
    if (claimable) {
      const node: any = document.getElementById('claim')

      toPng(node)
        .then(async function (dataUrl) {
          // console.log(blob)
          // await navigator.clipboard.write([
          //   new ClipboardItem({
          //     'image/png': blob as any,
          //   }),
          // ])

          const img = new Image()
          img.src = dataUrl
          const element: any = document.getElementById('claim-image')
          if (!element.childNodes.length) {
            element.appendChild(img)
          }
        })
        .catch(function (error) {
          console.error('oops, something went wrong!', error)
        })
    }
  }, [claimable])

  return (
    <>
      <Navbar />
      {account ? (
        claimable ? (
          <Claim details={claimable} />
        ) : (
          <NoClaim />
        )
      ) : (
        <Container maxWidth="lg">
          <Stack direction="column" justifyContent="center" alignItems="center" gap="1rem">
            <img src="/assets/art.svg" width={128} />
            <Typography variant="h5" component="p">
              Connect wallet to claim OSAK.
            </Typography>
            <Button size="large" variant="contained" className="rounded-lg" onClick={connectWallet}>
              Connect
            </Button>

            {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
            {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
          </Stack>
        </Container>
      )}
      <Footer />
    </>
  )
}
