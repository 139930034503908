import React from 'react'
import Container from '@mui/material/Container'
import { Box } from '@mui/system'

export function Footer() {
  return (
    <Box sx={{ position: 'fixed', bottom: '1rem', width: '100%' }}>
      <Box display="flex" justifyContent="center" sx={{ gap: '0.25rem' }}>
        Contact us at <a href="mailto:contact@osaka.win">contact@osaka.win</a>
      </Box>
    </Box>
  )
}
