import * as React from 'react'
import * as ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import { Provider } from 'jotai'
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import './index.css'
import App from './App'
import Home from './Home'
import SelectFarm from './SelectFarm'
import Farm from './Farm'
import SecondFarm from './SecondFarm'
import Stat from './Stat'
import reportWebVitals from './reportWebVitals'
import { defaultStore } from './utils/store'
import { Navbar } from './components/Navbar'

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement!)

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <React.Suspense fallback={<Navbar />}>
        <Home />
      </React.Suspense>
    ),
  },
  {
    path: '/airdrop',
    element: (
      <React.Suspense fallback={<Navbar />}>
        <Home />
      </React.Suspense>
    ),
  },
  {
    path: '/airdrop/:address',
    element: (
      <React.Suspense fallback={<Navbar />}>
        <Stat />
      </React.Suspense>
    ),
  },
  {
    path: '/farms',
    element: <SelectFarm />,
  },
  {
    path: '/first-farm',
    element: <Farm />,
  },
  {
    path: '/second-farm',
    element: <SecondFarm />,
  },
])

// All `Portal`-related components need to have the the main app wrapper element as a container
// so that the are in the subtree under the element used in the `important` option of the Tailwind's config.
const theme = createTheme({
  // palette: {
  //   mode: 'dark',
  // },
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: ['Nunito', '-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Roboto', '"Helvetica Neue"', 'Arial', 'sans-serif', '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(','),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        container: rootElement,
      },
    },
    MuiPopper: {
      defaultProps: {
        container: rootElement,
      },
    },
  },
})

root.render(
  <React.StrictMode>
    <Provider store={defaultStore}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <RouterProvider router={router} />
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
