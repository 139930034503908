import * as React from 'react'
import { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import { Stack } from '@mui/system'
import { Button, colors, Input, Paper, useMediaQuery } from '@mui/material'
import { Navbar } from './components/Navbar'
import { Countdown } from './components/Countdown'
import { useAtom } from 'jotai'
import {
  accountAtom,
  approvedLPAtom2,
  approveLP2,
  connectWallet,
  exit2,
  farmDetailsAtom2,
  getReward2,
  LPBalanceAtom2,
  stake2,
  withdraw2,
} from './utils/store'
import { formatEther, parseEther } from 'viem'
import { formatNumber } from './utils/format'
import CountUp from 'react-countup'

export default function App() {
  const [account] = useAtom(accountAtom)
  const [approved] = useAtom(approvedLPAtom2)
  const [details] = useAtom(farmDetailsAtom2)
  const [LPBalance] = useAtom(LPBalanceAtom2)
  const [inputValue, setInputValue] = useState('')
  const [oldEarned, setOldEarned] = useState(0)

  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)')

  function handleMax(e) {
    setInputValue(formatEther(LPBalance))
  }
  function handleInput(e) {
    setInputValue(e.target.value)
  }

  return (
    <>
      <Navbar />
      <Container maxWidth="lg">
        <Stack
          direction={isSmallDevice ? 'column' : 'row'}
          alignItems={isSmallDevice ? 'center' : 'normal'}
          justifyContent="center"
          gap="1rem"
        >
          {/* <img src="/assets/token.svg" height={128} /> */}
          <Typography variant="h5" component="h2">
            Deposit UNI-V2 and earn OSAK
          </Typography>
          <Typography variant="subtitle1" component="p">
            <Countdown timestamp={details.periodFinish} />
          </Typography>
          {/* <Typography variant="subtitle1" component="p">
            Withdrawals are locked until farm period has ended.
          </Typography> */}

          {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
          {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
        </Stack>

        <Container maxWidth="sm" className="mt-8">
          <Stack direction="row" justifyContent="center" gap="3rem">
            <Paper>
              <Stack
                width={360}
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="2rem"
                className="p-8"
              >
                <div className="circle">
                  <img src="/assets/token.svg" height={64} />
                </div>
                <Typography variant="h5" component="h3" fontWeight="700">
                  Osaka Protocol (OSAK)
                </Typography>
                <Stack justifyContent="center" alignItems="center">
                  <Typography variant="h5" component="p" fontWeight="700">
                    {details.earned ? (
                      <CountUp start={oldEarned} end={details.earned} onEnd={() => setOldEarned(details.earned)} />
                    ) : (
                      '0.000'
                    )}
                  </Typography>
                  <Typography variant="subtitle1" component="p" fontWeight="500" color={colors.blue[500]}>
                    OSAK earned
                  </Typography>
                </Stack>
                <Button
                  size="large"
                  variant="contained"
                  className="rounded-lg"
                  fullWidth
                  onClick={() => getReward2()}
                  disabled={!details.earned}
                >
                  Harvest
                </Button>

                <Button
                  size="large"
                  variant="contained"
                  className="rounded-lg"
                  fullWidth
                  onClick={() => exit2()}
                  disabled={!details.earned}
                >
                  Harvest & Withdraw
                </Button>

                <Button size="large" variant="contained" className="rounded-lg" fullWidth onClick={() => withdraw2()}>
                  Exit
                </Button>
              </Stack>
            </Paper>

            <Paper>
              <Stack
                width={360}
                direction="column"
                justifyContent="center"
                alignItems="center"
                gap="2rem"
                className="p-8"
              >
                <div className="circle">
                  🦄
                  {/* <img src="/assets/face.svg" height={64} /> */}
                </div>
                <Typography variant="h5" component="h3" fontWeight="700">
                  OSAK LP (UNI-V2)
                </Typography>
                <Stack justifyContent="center" alignItems="center">
                  <Typography variant="h5" component="p" fontWeight="700">
                    {details.staked ? formatNumber(details.staked, 2) : '0.000'}
                  </Typography>
                  <Typography variant="subtitle1" component="p" fontWeight="500" color={colors.blue[500]}>
                    UNI-V2 staked
                  </Typography>
                </Stack>
                {!account && (
                  <Button onClick={connectWallet} size="large" variant="contained" className="rounded-lg" fullWidth>
                    Connect
                  </Button>
                )}
                {account && !approved && (
                  <Button onClick={approveLP2} size="large" variant="contained" className="rounded-lg" fullWidth>
                    Approve UNI-V2
                  </Button>
                )}
                {account && approved && (
                  <Stack gap="0.5rem">
                    <Stack direction="row">
                      <Input placeholder="Stake amount" value={inputValue} onChange={handleInput} />
                      <Button onClick={handleMax}>MAX</Button>
                    </Stack>
                    <Typography>Balance: {formatNumber(Number(formatEther(LPBalance)))}</Typography>
                    <Button
                      variant="contained"
                      className="rounded-lg"
                      fullWidth
                      onClick={() => stake2(parseEther(inputValue as any))}
                    >
                      Stake
                    </Button>
                  </Stack>
                )}
              </Stack>
            </Paper>
            {/* <Paper>
              <Typography variant="h5" component="h3">
                Select a farm.
              </Typography>
            </Paper> */}
          </Stack>
          <Stack justifyContent="center" alignItems="center" marginY="2rem"></Stack>
        </Container>
      </Container>
    </>
  )
}
