import React, { useState } from 'react'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Slider from '@mui/material/Slider'
import { Stack } from '@mui/system'
import { Button, colors, Menu, MenuItem, Paper, useMediaQuery } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { useAtom } from 'jotai'
import { accountAtom, connectWallet } from '../utils/store'

export function Navbar() {
  const [account] = useAtom(accountAtom)
  const navigate = useNavigate()
  const isSmallDevice = useMediaQuery('only screen and (max-width : 768px)')

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Container maxWidth="lg">
      <div className="m-4 mb-8">
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" justifyContent="center" alignItems="center" onClick={() => navigate('/')} sx={{ cursor: 'pointer' }}>
            <img src="/assets/token.svg" height={42} />{' '}
            <Typography variant="body1" component="h1">
              Osaka Protocol
            </Typography>
          </Stack>
          {isSmallDevice && (
            <>
              <Button variant="contained" className="rounded-lg" aria-controls={open ? 'basic-menu' : undefined} aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={handleClick}>
                Menu
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
              >
                <MenuItem onClick={handleClose}>
                  <Button fullWidth variant="text" onClick={() => navigate('/')}>
                    Home
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Button fullWidth variant="text" onClick={() => navigate('/farms')}>
                    Farm
                  </Button>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className="w-full" href="https://t.me/Osakaprotocol" target="_blank">
                    <Button fullWidth variant="text">
                      Telegram
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className="w-full" href="https://medium.com/@mephis3/hello-frens-1531b48666cb" target="_blank">
                    <Button fullWidth variant="text">
                      Medium
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className="w-full" href="https://github.com/osakaprotocol" target="_blank">
                    <Button fullWidth variant="text">
                      Github
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <a className="w-full" href="https://bridge.osaka.win" target="_blank">
                    <Button fullWidth variant="text">
                      Bridge
                    </Button>
                  </a>
                </MenuItem>
                <MenuItem>
                  <Stack>
                    {account ? (
                      <Button variant="contained" className="rounded-lg">
                        {account.slice(0, 6) + '...' + account.slice(-4)}
                      </Button>
                    ) : (
                      <Button variant="contained" className="rounded-lg" onClick={connectWallet}>
                        Connect Wallet
                      </Button>
                    )}
                  </Stack>
                </MenuItem>
              </Menu>
            </>
          )}
          {!isSmallDevice && (
            <>
              <Stack direction="row" gap="1rem" alignItems="center">
                <Button variant="text" onClick={() => navigate('/')}>
                  Home
                </Button>
                <Button variant="text" onClick={() => navigate('/farms')}>
                  Farm
                </Button>
                <a href="https://t.me/Osakaprotocol" target="_blank">
                  <Button variant="text">Telegram</Button>
                </a>
                <a href="https://medium.com/@mephis3/hello-frens-1531b48666cb" target="_blank">
                  <Button variant="text">Medium</Button>
                </a>
                <a href="https://github.com/osakaprotocol" target="_blank">
                  <Button variant="text">Github</Button>
                </a>
                <a href="https://bridge.osaka.win" target="_blank">
                  <Button variant="text">Bridge</Button>
                </a>
              </Stack>
              <Stack>
                {account ? (
                  <Button variant="contained" className="rounded-lg">
                    {account.slice(0, 6) + '...' + account.slice(-4)}
                  </Button>
                ) : (
                  <Button variant="contained" className="rounded-lg" onClick={connectWallet}>
                    Connect Wallet
                  </Button>
                )}
              </Stack>
            </>
          )}
        </Stack>
      </div>
    </Container>
  )
}
