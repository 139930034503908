import * as React from 'react'
import { useEffect } from 'react'
import { useAtom } from 'jotai'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import { Box, Button, colors, Paper } from '@mui/material'

import { formatNumber, formatNumberToUSD } from './utils/format'
import { defaultStore, statAccountAtom, statAtom } from './utils/store'
import { useParams } from 'react-router-dom'
import { Navbar } from './components/Navbar'
import NoClaim from './NoClaim'

export default function Stat() {
  const [account] = useAtom(statAccountAtom)
  const [details] = useAtom(statAtom) as any
  const { address } = useParams()

  useEffect(() => {
    if (address) {
      defaultStore.set(statAccountAtom, address)
    }
  })

  if (!details)
    return (
      <>
        <Navbar />
        <NoClaim />
      </>
    )

  return (
    <>
      <Navbar />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Stack direction="row" justifyContent="center" alignItems="center" gap="1rem" id="claim" sx={{ background: 'white' }} paddingY="7rem" paddingX="4rem">
          <img src="/assets/art.svg" width={128} />
          <Stack direction="column" justifyContent="center" alignItems="center" gap="2rem">
            <Typography variant="h4" component="p">
              Following address{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {account.slice(0, 6) + '...' + account.slice(-4)}
              </Typography>
            </Typography>
            <Typography variant="h4" component="p">
              sold{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {formatNumber(details.shibAmount)}
              </Typography>{' '}
              SHIB for{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {formatNumberToUSD(details.shibAmount * details.shibPriceThen)}
              </Typography>
            </Typography>
            <Typography variant="h4" component="p">
              at ATH it was valued at{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {formatNumberToUSD(details.shibAmount * 0.0000819)}
              </Typography>
            </Typography>
            <Typography variant="h4" component="p">
              it is currently valued at{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {formatNumberToUSD(details.shibAmount * 0.00001)}
              </Typography>
              .
            </Typography>
            <Typography variant="h4" component="p">
              Airdrop claim is{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                {formatNumber(details.amount)}
              </Typography>{' '}
              OSAK
            </Typography>
          </Stack>
          <img src="/assets/art.svg" width={128} />

          {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
          {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
        </Stack>
      </Stack>
    </>
  )
}
