import 'viem/window'
import { createWalletClient, custom, createPublicClient, formatEther } from 'viem'
import { mainnet } from 'viem/chains'
import { atom, createStore } from 'jotai'

// const publicClient = createPublicClient({
//   chain: mainnet,
//   transport: http(),
// })

const walletClient = window.ethereum
  ? createWalletClient({
      chain: mainnet,
      transport: custom(window.ethereum!),
    })
  : ({} as any)

const publicClientLocal = window.ethereum
  ? createPublicClient({
      chain: mainnet,
      transport: custom(window.ethereum!),
    })
  : ({} as any)

const walletClientLocal = window.ethereum
  ? createWalletClient({
      chain: mainnet,
      transport: custom(window.ethereum!),
    })
  : ({} as any)

export const defaultStore = createStore()

export const accountAtom = atom('')

export const connectWallet = async () => {
  const [address] = await walletClient.requestAddresses()
  defaultStore.set(accountAtom, address)
  await Promise.all([
    setClaimed(),
    setApprovedLP(),
    setFarmDetails(),
    setLPBalance(),
    setApprovedLP2(),
    setFarmDetails2(),
    setLPBalance2(),
  ])
}

setFarmDetails()
setFarmDetails2()

// import { StandardMerkleTree } from '@openzeppelin/merkle-tree'
// import { CLAIM_LIST, CLAIM_LIST_INFO, SHIB_PRICES } from './constants'
import { CLAIM_ABI, ERC20_ABI, FARM_ABI } from './abis'

// export const CLAIM_TREE = StandardMerkleTree.of(CLAIM_LIST, ['address', 'uint256'])
const API_URL = `https://api.osaka.win/api/claim?account=`

export const claimableAtom = atom(async (get) => {
  const account = get(accountAtom).toLowerCase()
  if (account) {
    const test = await fetch(API_URL + account)
    const data = await test.json()
    if (data.proof) {
      return data
    }
  }

  return null
})

export const statAccountAtom = atom('')

export const statAtom = atom(async (get) => {
  const account = get(statAccountAtom).toLowerCase()
  if (account) {
    const test = await fetch(API_URL + account)
    const data = await test.json()
    if (data.proof) {
      return data
    }
  }

  return null
})

// console.log('Merkle Root:', CLAIM_TREE.root)

// for (const [i, v] of CLAIM_TREE.entries()) {
//   if (v[0] === '0xBe23CbB62064b8B1550Ae5ADA59c39d45b1E2081') {
//     const proof = CLAIM_TREE.getProof(i)
//     console.log('Value:', v)
//     console.log('Proof:', proof)
//   }
// }

export const CLAIM_ADDRESS = '0xf76ca11B6398C2Fc2aF7Af5a5ED4aFec8CF3eaD2'
export const OSAK_ADDRESS = '0xa21af1050f7b26e0cff45ee51548254c41ed6b5c'
export const OSAK_LP_ADDRESS = '0x05c024b441d2c288c2729b484bd4bcef3596aa51'
export const FARM_ONE_ADDRESS = '0x2Ca1CeC98e23C7FA6216FB8deAE5F02c15FA9479'
export const FARM_TWO_ADDRESS = '0xc663c970d0cf9f9d7813137aa51bfef1795ec890'
export const MAX_UINT_256 = '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff'

export async function claimOSAK() {
  const account: any = await defaultStore.get(accountAtom)
  const details: any = await defaultStore.get(claimableAtom)

  console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: CLAIM_ADDRESS,
    abi: CLAIM_ABI,
    functionName: 'claim',
    args: [details.proof, account, details.amountWei],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })

  defaultStore.set(claimedAtom, true)
}

export const claimedAtom = atom(false)

async function setClaimed() {
  const account = await defaultStore.get(accountAtom)
  const result = (await publicClientLocal.readContract({
    address: CLAIM_ADDRESS,
    abi: CLAIM_ABI,
    functionName: 'claimed',
    args: [account],
  })) as boolean
  defaultStore.set(claimedAtom, result)
}

export const approvedLPAtom = atom(false)
export const earnedAtom = atom(0)
export const earnedTextAtom = atom(0)
export const farmDetailsAtom = atom({
  periodFinish: 0,
  staked: 0,
  earned: 0,
  rewardRate: 0,
})
export const LPBalanceAtom = atom(0n)

export const approvedLPAtom2 = atom(false)
export const earnedAtom2 = atom(0)
export const earnedTextAtom2 = atom(0)
export const farmDetailsAtom2 = atom({
  periodFinish: 0,
  staked: 0,
  stakedRaw: 0n,
  earned: 0,
  rewardRate: 0,
})
export const LPBalanceAtom2 = atom(0n)

// export const firstFarmAtom = atom({
//   approvedLP: false,
//   earnedAtom: 0,
//   earnedText: 0
// })

async function setApprovedLP() {
  const account = await defaultStore.get(accountAtom)
  const result = await publicClientLocal.readContract({
    address: OSAK_LP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [account, FARM_ONE_ADDRESS],
  })

  if (result !== 0n) {
    defaultStore.set(approvedLPAtom, true)
  }
}

export async function approveLP() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: OSAK_LP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'approve',
    args: [FARM_ONE_ADDRESS, MAX_UINT_256],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })

  defaultStore.set(approvedLPAtom, true)
}

const farmOne = {
  address: FARM_ONE_ADDRESS,
  abi: FARM_ABI,
}

async function setFarmDetails() {
  const account = await defaultStore.get(accountAtom)
  const [periodFinish, staked, earned, rewardRate] = await publicClientLocal.multicall({
    contracts: [
      {
        ...farmOne,
        functionName: 'periodFinish',
      },
      {
        ...farmOne,
        functionName: 'balanceOf',
        args: [account],
      },
      {
        ...farmOne,
        functionName: 'earned',
        args: [account],
      },
      {
        ...farmOne,
        functionName: 'rewardRate',
      },
    ],
    multicallAddress: '0xca11bde05977b3631167028862be2a173976ca11',
  })

  if (account) {
    defaultStore.set(farmDetailsAtom, {
      periodFinish: Number(periodFinish.result),
      staked: Number(formatEther(staked.result)),
      earned: Number(formatEther(earned.result)),
      rewardRate: Number(formatEther(rewardRate.result)),
    })
  } else {
    defaultStore.set(farmDetailsAtom, {
      ...(await defaultStore.get(farmDetailsAtom)),
      periodFinish: Number(periodFinish.result),
      rewardRate: Number(formatEther(rewardRate.result)),
    })
  }
}

async function setLPBalance() {
  const account = await defaultStore.get(accountAtom)
  if (account) {
    const result = await publicClientLocal.readContract({
      address: OSAK_LP_ADDRESS,
      abi: ERC20_ABI,
      functionName: 'balanceOf',
      args: [account],
    })

    defaultStore.set(LPBalanceAtom, result)
  }
}

export async function stake(amount: bigint) {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_ONE_ADDRESS,
    abi: FARM_ABI,
    functionName: 'stake',
    args: [amount],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function getReward() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_ONE_ADDRESS,
    abi: FARM_ABI,
    functionName: 'getReward',
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function exit() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_ONE_ADDRESS,
    abi: FARM_ABI,
    functionName: 'exit',
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function setApprovedLP2() {
  const account = await defaultStore.get(accountAtom)
  const result = await publicClientLocal.readContract({
    address: OSAK_LP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'allowance',
    args: [account, FARM_TWO_ADDRESS],
  })

  if (result !== 0n) {
    defaultStore.set(approvedLPAtom2, true)
  }
}

export async function approveLP2() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: OSAK_LP_ADDRESS,
    abi: ERC20_ABI,
    functionName: 'approve',
    args: [FARM_TWO_ADDRESS, MAX_UINT_256],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })

  defaultStore.set(approvedLPAtom2, true)
}

const farmTwo = {
  address: FARM_TWO_ADDRESS,
  abi: FARM_ABI,
}

export async function setFarmDetails2() {
  const account = await defaultStore.get(accountAtom)
  const [periodFinish, staked, earned, rewardRate] = await publicClientLocal.multicall({
    contracts: [
      {
        ...farmTwo,
        functionName: 'periodFinish',
      },
      {
        ...farmTwo,
        functionName: 'balanceOf',
        args: [account],
      },
      {
        ...farmTwo,
        functionName: 'earned',
        args: [account],
      },
      {
        ...farmTwo,
        functionName: 'rewardRate',
      },
    ],
    multicallAddress: '0xca11bde05977b3631167028862be2a173976ca11',
  })

  if (account) {
    defaultStore.set(farmDetailsAtom2, {
      periodFinish: Number(periodFinish.result),
      staked: Number(formatEther(staked.result)),
      stakedRaw: staked.result,
      earned: Number(formatEther(earned.result)),
      rewardRate: Number(formatEther(rewardRate.result)),
    })
  } else {
    defaultStore.set(farmDetailsAtom2, {
      ...(await defaultStore.get(farmDetailsAtom2)),
      periodFinish: Number(periodFinish.result),
      rewardRate: Number(formatEther(rewardRate.result)),
    })
  }
}

export async function setLPBalance2() {
  const account = await defaultStore.get(accountAtom)
  if (account) {
    const result = await publicClientLocal.readContract({
      address: OSAK_LP_ADDRESS,
      abi: ERC20_ABI,
      functionName: 'balanceOf',
      args: [account],
    })

    defaultStore.set(LPBalanceAtom2, result)
  }
}

export async function stake2(amount: bigint) {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_TWO_ADDRESS,
    abi: FARM_ABI,
    functionName: 'stake',
    args: [amount],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function getReward2() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_TWO_ADDRESS,
    abi: FARM_ABI,
    functionName: 'getReward',
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function exit2() {
  const account: any = await defaultStore.get(accountAtom)
  // const details: any = await defaultStore.get(approvedLPAtom)

  // console.log(account, details)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_TWO_ADDRESS,
    abi: FARM_ABI,
    functionName: 'exit',
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

export async function withdraw2() {
  const account: any = await defaultStore.get(accountAtom)
  const { stakedRaw } = await defaultStore.get(farmDetailsAtom2)

  const { request } = await publicClientLocal.simulateContract({
    account,
    address: FARM_TWO_ADDRESS,
    abi: FARM_ABI,
    functionName: 'withdraw',
    args: [stakedRaw],
  })

  const hash = await walletClientLocal.writeContract(request)

  await publicClientLocal.waitForTransactionReceipt({ hash })
}

if (publicClientLocal.watchBlocks) {
  const unwatch = publicClientLocal.watchBlocks({
    onBlock: (block: any) => {
      setLPBalance()
      setFarmDetails()
      setLPBalance2()
      setFarmDetails2()
    },
  })
}
