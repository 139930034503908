import * as React from 'react'
import { useState } from 'react'
import { useAtom } from 'jotai'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/system'
import { Box, Button, colors, Paper } from '@mui/material'

import { formatNumber, formatNumberToUSD } from './utils/format'
import { accountAtom, claimedAtom, claimOSAK } from './utils/store'

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text)
  } else {
    return document.execCommand('copy', true, text)
  }
}

export default function Claim({ details }) {
  const [account] = useAtom(accountAtom)
  const [claimed] = useAtom(claimedAtom)
  const [toggleImage, setToggleImage] = useState(false)
  const [copied, setCopy] = useState(false)

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <Stack direction="row" justifyContent="center" alignItems="center" gap="1rem" id="claim" sx={{ background: 'white' }} paddingY="7rem" paddingX="4rem">
        <img src="/assets/art.svg" width={128} />
        <Stack direction="column" justifyContent="center" alignItems="center" gap="2rem">
          <Typography variant="h4" component="p">
            You sold{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              {formatNumber(details.shibAmount)}
            </Typography>{' '}
            SHIB for{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              {formatNumberToUSD(details.shibAmount * details.shibPriceThen)}
            </Typography>
          </Typography>
          <Typography variant="h4" component="p">
            at ATH it was valued at{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              {formatNumberToUSD(details.shibAmount * 0.0000819)}
            </Typography>
          </Typography>
          <Typography variant="h4" component="p">
            it is currently valued at{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              {formatNumberToUSD(details.shibAmount * 0.00001)}
            </Typography>
            .
          </Typography>
          <Typography variant="h4" component="p">
            Your airdrop claim is{' '}
            <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
              {formatNumber(details.amount)}
            </Typography>{' '}
            OSAK
          </Typography>
          <Box marginTop="2rem">
            <Typography variant="h4" component="span">
              <Button variant="contained" className="rounded-lg" onClick={() => claimOSAK()} disabled={true}>
                <Typography variant="h5" component="span">
                  {claimed ? 'Claimed' : 'Claim'}
                </Typography>
              </Button>{' '}
              at{' '}
              <Typography variant="h4" component="span" color={colors.blue[500]} fontWeight={700}>
                Osaka.win
              </Typography>{' '}
            </Typography>{' '}
          </Box>
          <Typography variant="body1" component="p">
            <b>Claim period is over.</b>
          </Typography>
        </Stack>
        <img src="/assets/art.svg" width={128} />

        {/* <Typography variant="h4" component="h1">
            Osaka Protocol
          </Typography> */}
        {/* <Slider className="my-4" defaultValue={30} classes={{ active: 'shadow-none' }} slotProps={{ thumb: { className: 'hover:shadow-none' } }} />
        <PopoverMenu />
        <ComboBox />
        <ProTip />
        <Copyright /> */}
      </Stack>
      <Stack direction="row" gap="1rem">
        <Button
          variant="contained"
          className="rounded-lg"
          onClick={() => {
            copyTextToClipboard(`https://osaka.win/airdrop/${account}`)
            setCopy(true)
          }}
        >
          {copied ? 'URL Copied!' : 'Share link'}
        </Button>
        <Button variant="contained" className="rounded-lg" onClick={() => setToggleImage(!toggleImage)}>
          Share image (scroll down)
        </Button>
      </Stack>
      <Box marginY="1rem">
        <Paper>
          <Stack width={600} id="claim-image" sx={{ display: toggleImage ? '' : 'none' }}></Stack>
        </Paper>
      </Box>
    </Stack>
  )
}
