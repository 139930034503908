import React from 'react'
import { useCountdown } from '../utils/hooks'

export function Countdown({ timestamp }: { timestamp: number }) {
  const [days, hours, minutes, seconds] = useCountdown(timestamp * 1000)
  if (Date.now() > timestamp * 1000) {
    return <span>Time Left : 0 day(s), 0 hour(s), 0 minute(s), 0 second(s)</span>
  }
  return (
    <span>
      Time Left : {days} day(s), {hours} hour(s), {minutes} minute(s), {seconds} second(s)
    </span>
  )
}
